
import {
  defineComponent,
  ref,
  watch,
} from 'vue';

import { ElLoading } from 'element-plus';
import { InfoFilled } from '@element-plus/icons';

import useDropdown from '@/uses/useDropdown';
import useInvoice from '@/uses/useInvoice';

import { IDropdownRequest } from '@/interfaces/Dropdown';

export default defineComponent({
  name: 'InvoiceCustomerContacts',

  components: {
    InfoFilled,
  },

  props: {
    customerNo: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const { fetchDropdownData } = useDropdown();

    const { dynamicInvoiceRequest } = useInvoice();

    const contactsDropdownRequest = ref<IDropdownRequest>({
      dataset_name: 'emp_customer_contact',
      dimensions: [
        { name: 'contactid' },
        { name: 'customer_no' },
        { name: 'name' },
        { name: 'title' },
        { name: 'mail' },
        { name: 'mobile' },
      ],
      metrics: [],
      filters: [
        {
          name: 'customer_no',
          type: 'varchar',
          operation: '=',
          value: props.customerNo,
        },
      ],
      order: [
        {
          name: 'updated_on',
          type: 'desc',
        },
      ],
      pagination: {
        page_size: 100,
        page_index: 0,
      },
    });

    const contactsDropdownResponse = ref();

    const addCustomerContact = () => {
      contactsDropdownResponse.value.push({
        contactid: '',
        name: '',
        title: '',
        mail: '',
        mobile: '',
      });
    };

    const upsertCustomerContact = async (contactIndex) => {
      const loadingInstance = ElLoading.service({ fullscreen: true });
      try {
        const contact = contactsDropdownResponse.value.find((el, index) => index === contactIndex);

        await dynamicInvoiceRequest({
          url: '/actionhub',
          method: 'POST',
          data: {
            update_values: {
              name: contact.name ? contact.name : '',
              title: contact.title ? contact.title : '',
              mail: contact.mail ? contact.mail : '',
              mobile: contact.mobile ? contact.mobile : '',
            },
            customer_no: props.customerNo,
            contact_id: contact.contactid ? contact.contactid : '',
            command: 'upsert_customer_contact',
          },
        });
        contactsDropdownResponse.value = await fetchDropdownData(
          contactsDropdownRequest.value,
        );
      } catch (error) {
        console.error(error);
      } finally {
        loadingInstance.close();
      }
    };

    watch(() => (props.customerNo), async (currentValue) => {
      if (currentValue) {
        contactsDropdownRequest.value = {
          dataset_name: 'emp_customer_contact',
          dimensions: [
            { name: 'contactid' },
            { name: 'customer_no' },
            { name: 'name' },
            { name: 'title' },
            { name: 'mail' },
            { name: 'mobile' },
          ],
          metrics: [],
          filters: [
            {
              name: 'customer_no',
              type: 'varchar',
              operation: '=',
              value: currentValue,
            },
          ],
          order: [
            {
              name: 'updated_on',
              type: 'desc',
            },
          ],
          pagination: {
            page_size: 100,
            page_index: 0,
          },
        };

        const loadingInstance = ElLoading.service({ fullscreen: true });
        try {
          contactsDropdownResponse.value = await fetchDropdownData(
            contactsDropdownRequest.value,
          );
        } catch (error) {
          console.error(error);
        } finally {
          loadingInstance.close();
        }
      }
    }, {
      immediate: true,
      deep: true,
    });

    return {
      contactsDropdownResponse,
      addCustomerContact,
      upsertCustomerContact,
    };
  },
});
