
import { defineComponent, ref, watch } from 'vue';

import { useRoute } from 'vue-router';

import { ElLoading } from 'element-plus';
import { InfoFilled } from '@element-plus/icons';

import moment from 'moment';

import useInvoice from '@/uses/useInvoice';

export default defineComponent({
  name: 'InvoicePromiseToPayDetails',

  components: {
    InfoFilled,
  },

  props: {
    promiseToPay: {
      type: String,
      required: true,
    },

    promiseToPayInitiatedDate: {
      type: String,
      required: true,
    },

    promiseToPayAmount: {
      type: String,
      required: true,
    },

    promiseToPayDueDate: {
      type: String,
      required: true,
    },

    promiseToPayCheckNumber: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const route = useRoute();

    const { dynamicInvoiceRequest } = useInvoice();

    const promiseToPayValue = ref<boolean>(false);

    const promiseToPayInitiatedDateValue = ref<string>('');

    const promiseToPayAmountValue = ref<string>('');

    const promiseToPayDueDateValue = ref<string>('');

    const promiseToPayCheckNumberValue = ref<string>('');

    const updateInvoice = async () => {
      const loadingInstance = ElLoading.service({ fullscreen: true });

      try {
        await dynamicInvoiceRequest({
          url: '/actionhub',
          method: 'POST',
          data: {
            update_values: {
              promise_to_pay: promiseToPayValue.value,
              promise_to_pay_initiated_date: promiseToPayInitiatedDateValue.value
                ? moment.utc(new Date(promiseToPayInitiatedDateValue.value).toLocaleString())
                  .format('YYYY-MM-DD')
                : null,
              promise_to_pay_amount: promiseToPayAmountValue.value
                ? promiseToPayAmountValue.value : null,
              promise_to_pay_due_date: promiseToPayDueDateValue.value
                ? moment.utc(new Date(promiseToPayDueDateValue.value).toLocaleString())
                  .format('YYYY-MM-DD')
                : null,
              promise_to_pay_check_number: promiseToPayCheckNumberValue.value
                ? promiseToPayCheckNumberValue.value : null,
            },
            invoice_id: route.params.invoiceId.toString(),
            command: 'update_invoice',
          },
        });
      } catch (error) {
        console.error(error);
      } finally {
        loadingInstance.close();
      }
    };

    watch(() => (props.promiseToPay), (currentValue) => {
      if (currentValue === 'true') {
        promiseToPayValue.value = true;
      } else {
        promiseToPayValue.value = false;
      }
    }, {
      immediate: true,
      deep: true,
    });

    watch(() => (props.promiseToPayInitiatedDate), (currentValue) => {
      if (currentValue) {
        promiseToPayInitiatedDateValue.value = currentValue;
      }
    }, {
      immediate: true,
      deep: true,
    });

    watch(() => (props.promiseToPayAmount), (currentValue) => {
      if (currentValue) {
        promiseToPayAmountValue.value = currentValue;
      }
    }, {
      immediate: true,
      deep: true,
    });

    watch(() => (props.promiseToPayDueDate), (currentValue) => {
      if (currentValue) {
        promiseToPayDueDateValue.value = currentValue;
      }
    }, {
      immediate: true,
      deep: true,
    });

    watch(() => (props.promiseToPayCheckNumber), (currentValue) => {
      if (currentValue) {
        promiseToPayCheckNumberValue.value = currentValue;
      }
    }, {
      immediate: true,
      deep: true,
    });

    return {
      promiseToPayValue,
      promiseToPayInitiatedDateValue,
      promiseToPayAmountValue,
      promiseToPayDueDateValue,
      promiseToPayCheckNumberValue,
      updateInvoice,
    };
  },
});
