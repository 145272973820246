import { useStore } from 'vuex';
import { computed, ComputedRef } from 'vue';

import {
  IInvoiceCustomerContactRequest,
  IInvoiceCustomerNoteUpdateRequest,
  IInvoiceNoteRequest, IInvoiceNoteStatusRequest,
  IInvoiceRequest,
  IInvoiceResponse,
  IInvoiceUpdateRequest,
} from '@/interfaces/Invoice';

export default function useGrid(): {
  invoice: ComputedRef<IInvoiceResponse>;
  fetchInvoice: (payload: {
    viewName: string;
    gridName: string;
    gridRequest: IInvoiceRequest;
  }) => Promise<void>;
  dynamicInvoiceRequest: (payload: {
    url: string;
    method: string;
    data: IInvoiceNoteRequest |
      IInvoiceUpdateRequest|
      IInvoiceCustomerNoteUpdateRequest |
      IInvoiceNoteStatusRequest |
      IInvoiceCustomerContactRequest;
  }) => Promise<void>;
  } {
  const store = useStore();

  const invoice = computed((): IInvoiceResponse => store.getters['Invoice/getInvoiceResponse']);

  async function fetchInvoice(
    payload: {viewName: string; gridName: string; gridRequest: IInvoiceRequest},
  ): Promise<void> {
    try {
      await store.dispatch('Invoice/fetchInvoice', payload);
    } catch (error) {
      console.error(error);
    }
  }

  async function dynamicInvoiceRequest(
    payload: {
      url: string;
      method: string;
      data: IInvoiceNoteRequest |
        IInvoiceUpdateRequest|
        IInvoiceCustomerNoteUpdateRequest |
        IInvoiceNoteStatusRequest |
        IInvoiceCustomerContactRequest;
    },
  ): Promise<void> {
    try {
      await store.dispatch('Invoice/dynamicInvoiceRequest', payload);
    } catch (error) {
      console.error(error);
    }
  }

  return {
    invoice,
    fetchInvoice,
    dynamicInvoiceRequest,
  };
}
