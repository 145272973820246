
import { defineComponent, onMounted, ref } from 'vue';

import { ElLoading } from 'element-plus';

import useDropdown from '@/uses/useDropdown';

import { IDropdownRequest } from '@/interfaces/Dropdown';

export default defineComponent({
  name: 'InvoiceNotesDialog',

  emits: ['save-note'],

  setup() {
    const { fetchDropdownData } = useDropdown();

    const note = ref<string>('');

    const status = ref<string>('');

    const isDialogOpen = ref<boolean>(false);

    const invoiceStatusIndicatorDropdownRequest = ref<IDropdownRequest>({
      dataset_name: 'invoice_status',
      dimensions: [{
        name: 'id',
      }, {
        name: 'type',
      }, {
        name: 'key',
      }, {
        name: 'value',
      }],
      metrics: [],
      filters: [],
      order: [],
      pagination: {
        page_size: 100,
        page_index: 0,
      },
    });

    const invoiceStatusIndicatorDropdownResponse = ref();

    const open = ():void => {
      isDialogOpen.value = true;
    };

    const close = ():void => {
      isDialogOpen.value = false;
      note.value = '';
      status.value = '';
    };

    onMounted(async () => {
      const loadingInstance = ElLoading.service({ fullscreen: true });
      try {
        invoiceStatusIndicatorDropdownResponse.value = await fetchDropdownData(
          invoiceStatusIndicatorDropdownRequest.value,
        );
      } catch (error) {
        console.error(error);
      } finally {
        loadingInstance.close();
      }
    });

    return {
      note,
      status,
      isDialogOpen,
      invoiceStatusIndicatorDropdownResponse,
      open,
      close,
    };
  },
});
