import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_el_image = _resolveComponent("el-image")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_card = _resolveComponent("el-card")!

  return (_openBlock(), _createBlock(_component_el_card, null, {
    header: _withCtx(() => [
      _createElementVNode("strong", null, [
        _createVNode(_component_font_awesome_icon, {
          icon: "angle-double-right",
          class: "el-text__primary"
        }),
        _createTextVNode(" Invoice Status ")
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        _createVNode(_component_el_row, {
          type: "flex",
          justify: "space-around",
          align: "top"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_image, {
              src: `/images/status/${_ctx.statusImageUrl}`,
              alt: _ctx.invoiceStatusIndicatorValue,
              style: {"width":"24px","height":"24px"}
            }, null, 8, ["src", "alt"]),
            _createVNode(_component_el_form_item, null, {
              default: _withCtx(() => [
                _createVNode(_component_el_select, {
                  modelValue: _ctx.invoiceStatusIndicatorValue,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.invoiceStatusIndicatorValue) = $event)),
                  onChange: _ctx.updateInvoice
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.invoiceStatusIndicatorDropdownResponse, (option, index) => {
                      return (_openBlock(), _createBlock(_component_el_option, {
                        key: index,
                        label: option.value,
                        value: option.key
                      }, null, 8, ["label", "value"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["modelValue", "onChange"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }))
}