import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "dialog-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    title: "Set Invoices Notes & Status",
    modelValue: _ctx.isDialogOpen,
    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.isDialogOpen) = $event)),
    onClose: _cache[5] || (_cache[5] = ($event: any) => (_ctx.isDialogOpen = false))
  }, {
    footer: _withCtx(() => [
      _createElementVNode("span", _hoisted_1, [
        _createVNode(_component_el_button, {
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isDialogOpen = false)),
          icon: "el-icon-close"
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Cancel ")
          ]),
          _: 1
        }),
        _createVNode(_component_el_button, {
          type: "success",
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('save-note'))),
          icon: "el-icon-plus"
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Save ")
          ]),
          _: 1
        })
      ])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_el_form, null, {
        default: _withCtx(() => [
          _createVNode(_component_el_form_item, { label: "Note" }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: _ctx.note,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.note) = $event)),
                rows: 4,
                type: "textarea"
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, { label: "Status" }, {
            default: _withCtx(() => [
              _createVNode(_component_el_select, {
                modelValue: _ctx.status,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.status) = $event))
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.invoiceStatusIndicatorDropdownResponse, (option, index) => {
                    return (_openBlock(), _createBlock(_component_el_option, {
                      key: index,
                      label: option.value,
                      value: option.key
                    }, null, 8, ["label", "value"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["modelValue"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue"]))
}