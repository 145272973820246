
import {
  defineComponent, ref, onMounted, watch,
} from 'vue';

import { ElLoading } from 'element-plus';

import { useRoute } from 'vue-router';

import qs from 'qs';

import useDropdown from '@/uses/useDropdown';
import useInvoice from '@/uses/useInvoice';

import { IColumn } from '@/interfaces/View';
import { IDropdownRequest } from '@/interfaces/Dropdown';

export default defineComponent({
  name: 'InvoiceNotes',

  props: {
    notes: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const route = useRoute();

    const { fetchDropdownData } = useDropdown();

    const { fetchInvoice, dynamicInvoiceRequest } = useInvoice();

    const noteValue = ref<string>('');

    const notesDropdownRequest = ref<IDropdownRequest>({
      dataset_name: 'invoice_custom_audit',
      dimensions: [
        { name: 'notestoshow' },
        { name: 'updated_on' },
      ],
      metrics: [],
      filters: [
        {
          name: 'invoice_id',
          type: 'int4',
          operation: '=',
          value: route.params.invoiceId.toString(),
        },
      ],
      order: [
        {
          name: 'updated_on',
          type: 'desc',
        },
      ],
      pagination: {
        page_size: 100,
        page_index: 0,
      },
    });

    const notesDropdownResponse = ref();

    const saveNote = async () => {
      const loadingInstance = ElLoading.service({ fullscreen: true });

      try {
        if (noteValue.value) {
          await dynamicInvoiceRequest({
            url: '/actionhub',
            method: 'POST',
            data: {
              note: noteValue.value,
              invoice_id: [route.params.invoiceId.toString()],
              command: 'set_invoices_note',
            },
          });

          notesDropdownResponse.value = await fetchDropdownData(
            notesDropdownRequest.value,
          );

          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const params: {
          column: IColumn;
          gridName: string;
          viewName: string;
          dataSource: string;
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
        } = qs.parse(route.query);

          await fetchInvoice({
            viewName: 'invoices_extended',
            gridName: 'invoices_grid',
            gridRequest: {
              page_size: 1,
              page_index: 0,
              orders: [],
              filters: [{
                id: 'invoice_id',
                type: params.column.type,
                index: params.column.index,
                visible: params.column.visible,
                field_id: 'invoice_id',
                operation: '=',
                data_source: params.dataSource,
                filter_text: route.params.invoiceId.toString(),
                header_text: params.column.header_text,
                default_filter: '',
              }],
              drillthrough: null,
            },
          });
        }
      } catch (error) {
        console.error(error);
      } finally {
        loadingInstance.close();
      }
    };

    watch(() => (props.notes), (currentValue) => {
      if (currentValue) {
        noteValue.value = currentValue;
      }
    }, {
      immediate: true,
      deep: true,
    });

    onMounted(async () => {
      const loadingInstance = ElLoading.service({ fullscreen: true });
      try {
        notesDropdownResponse.value = await fetchDropdownData(
          notesDropdownRequest.value,
        );
      } catch (error) {
        console.error(error);
      } finally {
        loadingInstance.close();
      }
    });

    return {
      noteValue,
      notesDropdownResponse,
      saveNote,
    };
  },
});
