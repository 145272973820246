
import {
  defineComponent, ref, watch,
} from 'vue';

import { ElLoading, ElNotification, ElUpload } from 'element-plus';

import useAuthentication from '@/uses/useAuthentication';
import useDocument from '@/uses/useDocument';

export default defineComponent({
  name: 'InvoiceDocuments',

  props: {
    customerNo: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const { email } = useAuthentication();

    const {
      documents,
      fetchDocuments,
      createDocument,
      deleteDocument,
      downloadDocument,
    } = useDocument();

    const uploadRef = ref<InstanceType<typeof ElUpload>>();

    const document = ref();

    const fileName = ref();

    const toBase64 = (file) => new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

    const onChange = async (file) => {
      if (file) {
        document.value = await toBase64(file.raw);
        fileName.value = file.name;
      } else {
        document.value = '';
        fileName.value = '';
      }
    };

    const saveDocument = async () => {
      const loadingInstance = ElLoading.service({ fullscreen: true });
      try {
        await createDocument(email.value, props.customerNo, document.value, fileName.value);
        await fetchDocuments(props.customerNo);
        document.value = '';
        fileName.value = '';
        // eslint-disable-next-line no-unused-expressions
        uploadRef.value?.clearFiles();
      } catch (error) {
        ElNotification({
          title: 'Error saving the document',
          type: 'error',
        });
      } finally {
        loadingInstance.close();
      }
    };

    const removeDocument = async (documentId: number) => {
      const loadingInstance = ElLoading.service({ fullscreen: true });
      try {
        await deleteDocument(documentId);
        await fetchDocuments(props.customerNo);
      } catch (error) {
        ElNotification({
          title: 'Error retrieving the documents',
          type: 'error',
        });
      } finally {
        loadingInstance.close();
      }
    };

    watch(() => (props.customerNo), async (currentValue) => {
      const loadingInstance = ElLoading.service({ fullscreen: true });
      try {
        await fetchDocuments(currentValue);
      } catch (error) {
        ElNotification({
          title: 'Error retrieving the documents',
          type: 'error',
        });
      } finally {
        loadingInstance.close();
      }
    }, {
      immediate: true,
      deep: true,
    });

    return {
      fileName,
      uploadRef,
      documents,
      saveDocument,
      removeDocument,
      downloadDocument,
      onChange,
    };
  },
});
