
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'InvoiceHeader',

  props: {
    account: {
      type: String,
      required: true,
    },

    customerNo: {
      type: String,
      required: true,
    },

    contact: {
      type: String,
      required: true,
    },

    customerAddress: {
      type: String,
      required: true,
    },

    salespersonID: {
      type: String,
      required: true,
    },
  },
});
