import { useStore } from 'vuex';

import { IDropdownRequest } from '@/interfaces/Dropdown';

export default function useDropdown(): {
  fetchDropdownData: (payload: IDropdownRequest) => Promise<void>;
  } {
  const store = useStore();

  async function fetchDropdownData(payload: IDropdownRequest): Promise<void> {
    try {
      const response = await store.dispatch('Dropdown/fetchDropdownData', payload);
      return response;
    } catch (error) {
      throw error.response;
    }
  }

  return {
    fetchDropdownData,
  };
}
