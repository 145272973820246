
import {
  defineComponent, onMounted, ref, watch,
} from 'vue';

import { ElLoading } from 'element-plus';

import { useRoute } from 'vue-router';

import qs from 'qs';

import useDropdown from '@/uses/useDropdown';
import useInvoice from '@/uses/useInvoice';

import { IColumn } from '@/interfaces/View';
import { IDropdownRequest } from '@/interfaces/Dropdown';

export default defineComponent({
  name: 'InvoiceStatus',

  props: {
    statusImageUrl: {
      type: String,
      required: true,
    },

    invoiceStatusIndicator: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const invoiceStatusIndicatorValue = ref<string>('');

    const route = useRoute();

    const { fetchDropdownData } = useDropdown();

    const { fetchInvoice, dynamicInvoiceRequest } = useInvoice();

    const invoiceStatusIndicatorDropdownRequest = ref<IDropdownRequest>({
      dataset_name: 'invoice_status',
      dimensions: [{
        name: 'id',
      }, {
        name: 'type',
      }, {
        name: 'key',
      }, {
        name: 'value',
      }],
      metrics: [],
      filters: [],
      order: [],
      pagination: {
        page_size: 100,
        page_index: 0,
      },
    });

    const invoiceStatusIndicatorDropdownResponse = ref();

    const updateInvoice = async () => {
      const loadingInstance = ElLoading.service({ fullscreen: true });

      try {
        await dynamicInvoiceRequest({
          url: '/actionhub',
          method: 'POST',
          data: {
            update_values: {
              invoice_status_indicator: invoiceStatusIndicatorValue.value,
            },
            invoice_id: route.params.invoiceId.toString(),
            command: 'update_invoice',
          },
        });

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const params: {
          column: IColumn;
          gridName: string;
          viewName: string;
          dataSource: string;
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
        } = qs.parse(route.query);

        await fetchInvoice({
          viewName: 'invoices_extended',
          gridName: 'invoices_grid',
          gridRequest: {
            page_size: 1,
            page_index: 0,
            orders: [],
            filters: [{
              id: 'invoice_id',
              type: params.column.type,
              index: params.column.index,
              visible: params.column.visible,
              field_id: 'invoice_id',
              operation: '=',
              data_source: params.dataSource,
              filter_text: route.params.invoiceId.toString(),
              header_text: params.column.header_text,
              default_filter: '',
            }],
            drillthrough: null,
          },
        });
      } catch (error) {
        console.error(error);
      } finally {
        loadingInstance.close();
      }
    };

    onMounted(async () => {
      const loadingInstance = ElLoading.service({ fullscreen: true });
      try {
        invoiceStatusIndicatorDropdownResponse.value = await fetchDropdownData(
          invoiceStatusIndicatorDropdownRequest.value,
        );
      } catch (error) {
        console.error(error);
      } finally {
        loadingInstance.close();
      }
    });

    watch(() => (props.invoiceStatusIndicator), (currentValue) => {
      if (currentValue) {
        invoiceStatusIndicatorValue.value = currentValue;
      }
    }, {
      immediate: true,
      deep: true,
    });

    return {
      invoiceStatusIndicatorValue,
      invoiceStatusIndicatorDropdownResponse,
      updateInvoice,
    };
  },

});
