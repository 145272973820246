
import {
  defineComponent, onMounted, ref, watch,
} from 'vue';

import { ElLoading } from 'element-plus';
import { InfoFilled } from '@element-plus/icons';

import qs from 'qs';

import moment from 'moment';

import { useRoute } from 'vue-router';

import useDropdown from '@/uses/useDropdown';
import useInvoice from '@/uses/useInvoice';

import { IColumn } from '@/interfaces/View';
import { IDropdownRequest } from '@/interfaces/Dropdown';

export default defineComponent({
  name: 'InvoiceDetails',

  components: {
    InfoFilled,
  },

  props: {
    invoiceSent: {
      type: String,
      required: true,
    },

    invoiceSentDate: {
      type: Date,
      required: true,
    },

    invoiceSentMethod: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const route = useRoute();

    const { fetchDropdownData } = useDropdown();

    const { fetchInvoice, dynamicInvoiceRequest } = useInvoice();

    const invoiceSentValue = ref<boolean>(false);

    const invoiceSentDateValue = ref<Date>();

    const invoiceSentMethodValue = ref<string>('');

    const invoiceSentMethodDropdownRequest = ref<IDropdownRequest>({
      dataset_name: 'invoice_sent_method',
      dimensions: [{
        name: 'id',
      }, {
        name: 'type',
      }, {
        name: 'key',
      }, {
        name: 'value',
      }],
      metrics: [],
      filters: [],
      order: [],
      pagination: {
        page_size: 100,
        page_index: 0,
      },
    });

    const invoiceSentMethodDropdownResponse = ref();

    const updateInvoice = async () => {
      const loadingInstance = ElLoading.service({ fullscreen: true });

      try {
        await dynamicInvoiceRequest({
          url: '/actionhub',
          method: 'POST',
          data: {
            update_values: {
              invoices_sent: invoiceSentValue.value,
              invoices_sent_date: invoiceSentDateValue.value
                ? moment.utc(new Date(invoiceSentDateValue.value).toLocaleString())
                  .format('YYYY-MM-DD')
                : null,
              invoice_sent_method: invoiceSentMethodValue.value,
            },
            invoice_id: route.params.invoiceId.toString(),
            command: 'update_invoice',
          },
        });

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const params: {
          column: IColumn;
          gridName: string;
          viewName: string;
          dataSource: string;
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
        } = qs.parse(route.query);

        await fetchInvoice({
          viewName: 'invoices_extended',
          gridName: 'invoices_grid',
          gridRequest: {
            page_size: 1,
            page_index: 0,
            orders: [],
            filters: [{
              id: 'invoice_no',
              type: params.column.type,
              index: params.column.index,
              visible: params.column.visible,
              field_id: 'invoice_no',
              operation: '=',
              data_source: params.dataSource,
              filter_text: route.params.invoiceId.toString(),
              header_text: params.column.header_text,
              default_filter: '',
            }],
            drillthrough: null,
          },
        });
      } catch (error) {
        console.error(error);
      } finally {
        loadingInstance.close();
      }
    };

    watch(() => (props.invoiceSent), (currentValue) => {
      if (currentValue === 'true') {
        invoiceSentValue.value = true;
      } else {
        invoiceSentValue.value = false;
      }
    }, {
      immediate: true,
      deep: true,
    });

    watch(() => (props.invoiceSentDate), (currentValue) => {
      if (currentValue) {
        invoiceSentDateValue.value = currentValue;
      }
    }, {
      immediate: true,
      deep: true,
    });

    watch(() => (props.invoiceSentMethod), (currentValue) => {
      if (currentValue) {
        invoiceSentMethodValue.value = currentValue;
      }
    }, {
      immediate: true,
      deep: true,
    });

    watch(() => (invoiceSentValue.value), (currentValue) => {
      if (currentValue) {
        invoiceSentDateValue.value = new Date();
      }
    }, {
      immediate: true,
      deep: true,
    });

    watch(() => (invoiceSentDateValue.value), (currentValue) => {
      if (currentValue) {
        invoiceSentValue.value = true;
      } else {
        invoiceSentValue.value = false;
      }
    }, {
      immediate: true,
      deep: true,
    });

    onMounted(async () => {
      const loadingInstance = ElLoading.service({ fullscreen: true });

      try {
        invoiceSentMethodDropdownResponse.value = await fetchDropdownData(
          invoiceSentMethodDropdownRequest.value,
        );
      } catch (error) {
        console.error(error);
      } finally {
        loadingInstance.close();
      }
    });

    return {
      invoiceSentValue,
      invoiceSentDateValue,
      invoiceSentMethodValue,
      invoiceSentMethodDropdownResponse,
      updateInvoice,
    };
  },
});
