
import {
  defineComponent, onMounted, ref, watch,
} from 'vue';

import { ElLoading } from 'element-plus';
import { InfoFilled } from '@element-plus/icons';

import moment from 'moment';

import { useRoute } from 'vue-router';

import useDropdown from '@/uses/useDropdown';
import useInvoice from '@/uses/useInvoice';

import { IDropdownRequest } from '@/interfaces/Dropdown';

export default defineComponent({
  name: 'InvoiceDetails',

  components: {
    InfoFilled,
  },

  props: {
    account: {
      type: String,
      required: true,
    },

    paymentTerms: {
      type: String,
      required: true,
    },

    customerNo: {
      type: String,
      required: true,
    },

    depositRequired: {
      type: String,
      required: true,
    },

    invoiceNo: {
      type: String,
      required: true,
    },

    invoiceId: {
      type: String,
      required: true,
    },

    netDaysFromInvoiceDate: {
      type: Number,
      required: true,
    },

    invoiceDate: {
      type: String,
      required: true,
    },

    reasonForDelinquency: {
      type: String,
      required: true,
    },

    invoiceAmount: {
      type: String,
      required: true,
    },

    reasonForBadDebtWriteOff: {
      type: String,
      required: true,
    },

    thirdPartyCollector: {
      type: String,
      required: true,
    },

    balanceDue: {
      type: String,
      required: true,
    },

    collectorCode: {
      type: String,
      required: true,
    },

    hbOrderNo: {
      type: Number,
      required: true,
    },

    hbCustomerPoNo: {
      type: String,
      required: true,
    },

    customerTermsCode: {
      type: String,
      required: true,
    },

    customerTermsDesc: {
      type: String,
      required: true,
    },

  },

  setup(props) {
    const route = useRoute();

    const { fetchDropdownData } = useDropdown();

    const { dynamicInvoiceRequest } = useInvoice();

    const reasonForDelinquencyValue = ref<string>('');

    const reasonForBadDebtWriteOffValue = ref<string>('');

    const reasonForDelinquencyDropdownRequest = ref<IDropdownRequest>({
      dataset_name: 'reason_for_delinquency',
      dimensions: [{
        name: 'id',
      }, {
        name: 'type',
      }, {
        name: 'key',
      }, {
        name: 'value',
      }],
      metrics: [],
      filters: [],
      order: [],
      pagination: {
        page_size: 100,
        page_index: 0,
      },
    });

    const reasonForDelinquencyDropdownResponse = ref();

    const updateInvoice = async () => {
      const loadingInstance = ElLoading.service({ fullscreen: true });

      try {
        await dynamicInvoiceRequest({
          url: '/actionhub',
          method: 'POST',
          data: {
            update_values: {
              reason_for_delinquency: reasonForDelinquencyValue.value,
              reason_for_bad_debt_write_off: reasonForBadDebtWriteOffValue.value,
            },
            invoice_id: route.params.invoiceId.toString(),
            command: 'update_invoice',
          },
        });
      } catch (error) {
        console.error(error);
      } finally {
        loadingInstance.close();
      }
    };

    watch(() => (props.reasonForDelinquency), (currentValue) => {
      if (currentValue !== null) {
        reasonForDelinquencyValue.value = currentValue;
      }
    }, {
      immediate: true,
      deep: true,
    });

    watch(() => (props.reasonForBadDebtWriteOff), (currentValue) => {
      if (currentValue !== null) {
        reasonForBadDebtWriteOffValue.value = currentValue;
      }
    }, {
      immediate: true,
      deep: true,
    });

    onMounted(async () => {
      const loadingInstance = ElLoading.service({ fullscreen: true });
      try {
        reasonForDelinquencyDropdownResponse.value = await fetchDropdownData(
          reasonForDelinquencyDropdownRequest.value,
        );
      } catch (error) {
        console.error(error);
      } finally {
        loadingInstance.close();
      }
    });

    return {
      reasonForDelinquencyValue,
      reasonForBadDebtWriteOffValue,
      reasonForDelinquencyDropdownResponse,
      updateInvoice,
      moment,
    };
  },
});
