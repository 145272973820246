
import {
  defineComponent,
  ref,
  watch,
} from 'vue';

import { ElLoading } from 'element-plus';

import useDropdown from '@/uses/useDropdown';
import useInvoice from '@/uses/useInvoice';

import { IDropdownRequest } from '@/interfaces/Dropdown';

export default defineComponent({
  name: 'InvoiceNotes',

  props: {
    customerNo: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const { fetchDropdownData } = useDropdown();

    const { dynamicInvoiceRequest } = useInvoice();

    const titleValue = ref<string>('');

    const noteValue = ref<string>('');

    const notesDropdownRequest = ref<IDropdownRequest>({
      dataset_name: 'emp_customer_note',
      dimensions: [
        { name: 'id' },
        { name: 'customer_no' },
        { name: 'notes' },
        { name: 'title' },
        { name: 'updated_on' },
      ],
      metrics: [],
      filters: [
        {
          name: 'customer_no',
          type: 'varchar',
          operation: '=',
          value: props.customerNo,
        },
      ],
      order: [
        {
          name: 'updated_on',
          type: 'desc',
        },
      ],
      pagination: {
        page_size: 100,
        page_index: 0,
      },
    });

    const notesDropdownResponse = ref();

    const saveNote = async () => {
      const loadingInstance = ElLoading.service({ fullscreen: true });

      try {
        await dynamicInvoiceRequest({
          url: '/actionhub',
          method: 'POST',
          data: {
            update_values: {
              notes: noteValue.value,
              title: titleValue.value,
            },
            customer_no: props.customerNo,
            command: 'add_customer_note',
          },
        });
        notesDropdownResponse.value = await fetchDropdownData(
          notesDropdownRequest.value,
        );
      } catch (error) {
        console.error(error);
      } finally {
        noteValue.value = '';
        titleValue.value = '';
        loadingInstance.close();
      }
    };

    watch(() => (props.customerNo), async (currentValue) => {
      if (currentValue) {
        notesDropdownRequest.value = {
          dataset_name: 'emp_customer_note',
          dimensions: [
            { name: 'id' },
            { name: 'customer_no' },
            { name: 'notes' },
            { name: 'title' },
          ],
          metrics: [],
          filters: [
            {
              name: 'customer_no',
              type: 'varchar',
              operation: '=',
              value: currentValue,
            },
          ],
          order: [
            {
              name: 'updated_on',
              type: 'desc',
            },
          ],
          pagination: {
            page_size: 100,
            page_index: 0,
          },
        };
        const loadingInstance = ElLoading.service({ fullscreen: true });
        try {
          notesDropdownResponse.value = await fetchDropdownData(
            notesDropdownRequest.value,
          );
        } catch (error) {
          console.error(error);
        } finally {
          loadingInstance.close();
        }
      }
    }, {
      immediate: true,
      deep: true,
    });

    return {
      titleValue,
      noteValue,
      notesDropdownResponse,
      saveNote,
    };
  },
});
